import React, { Fragment } from 'react';

import { LoadMoreResultsButton } from './LoadMoreResultsButton';
import { NoAvailabilityMessage } from './NoAvailability/NoAvailabilityMessage';
import { resultsPerGroup, SearchResultGroup } from './SearchResultGroup';
import { ClassNameProps } from '@ComponentProps';
import { LoadingCard } from '@Pages/search-results/card/LoadingCard';
import { useSearchResultsStore } from '@Stores/StoreContext';

const getView = (fetching: boolean, paginating: boolean, resultsCount: number) => {
  if (fetching && !paginating) {
    return 'loading';
  }
  if (paginating) {
    return 'paginating';
  }
  if (!resultsCount) {
    return 'no-results';
  }

  return 'normal';
};

export const SearchResults: React.FC<ClassNameProps> = ({ className }) => {
  const [fetching, paginating, results] = useSearchResultsStore((state) => [
    state.fetching,
    state.paginating,
    state.results,
  ]);
  const view = getView(fetching, paginating, results.length);

  return (
    <section className={className}>
      {view === 'loading' &&
        // we need to render 8 cards to fill the viewport, so SearchResultsRecommendedHotels is not hydrated too early
        [...Array(8).keys()].map((_, index) => (
          <LoadingCard
            key={index}
            sx={{
              marginBottom: 'l',
            }}
          />
        ))}

      {view === 'no-results' && (
        <NoAvailabilityMessage
          sx={{
            marginTop: ['xl', '3xl', '4xl'],
          }}
        />
      )}

      {(view === 'normal' || view === 'paginating') && (
        <Fragment>
          {[...new Array(Math.ceil(results.length / resultsPerGroup))].map((_, groupIndex) => (
            <SearchResultGroup
              key={groupIndex}
              groupIndex={groupIndex}
              results={results}
              lazy={groupIndex > 0}
            />
          ))}

          <LoadMoreResultsButton
            loading={!!paginating}
            sx={{
              marginTop: 's',
              marginBottom: ['xl', null, 's'],
            }}
          />
        </Fragment>
      )}
    </section>
  );
};
